import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

//Okta Sdks
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";

//Bootstrap v5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//OIDC configuration
import sampleConfig from "@/config";

//Font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowRight, faCheck, faCircleXmark, faCopy, faHourglassHalf, faLock, faPlus, faQuestion, faToggleOff, faToggleOn, faUnlockKeyhole, faUserAlt, faUserGear, faUserPlus, faUsersGear, faUserShield, faUserTie, faXmark, } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

library.add( faUserAlt, faLock, faCheck, faUserTie, faCopy, faQuestion, faXmark, faPenToSquare, faUnlockKeyhole, faCircleXmark, faUserGear, faUsersGear, faUserPlus, faUserTie, faUserShield, faToggleOff, faToggleOn, faArrowRight, faPlus, faHourglassHalf)

const oktaAuth = new OktaAuth(sampleConfig.oidc);

createApp(App)
  .use(router)
  .use(OktaVue, {
    oktaAuth,
    onAuthRequired: () => {
      router.push("/login");
    },
    onAuthResume: () => {
      router.push("/login");
    },
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Datepicker', Datepicker)
  .mount("#app");
