<template>
  <div id="navBar" class="d-flex justify-content-around align-items-center mt-2">
    <span class="d-flex justify-content-start w-25 me-5" v-if="authState && authState.isAuthenticated">Bonjour,</span>
    <div id="nav" class="d-flex justify-content-around align-items-center w-50">
      <router-link class="text-decoration-none" to="/">Accueil</router-link>      
      <router-link class="text-decoration-none" to="/login" v-if="authState && !authState.isAuthenticated">Connexion</router-link>
      <router-link class="text-decoration-none" to="/profile" v-if="authState && authState.isAuthenticated">Mon profil</router-link>
      <button class="btn btn-outline-primary" v-if="authState && authState.isAuthenticated" @click="logout()">Déconnexion</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AlterNavBar",
  data() {
    return {};
  },
  methods: {
    async logout() {
     axios.defaults.headers.common["Authorization"] = `Bearer ${this.$data.accessToken}`;
      axios.post(`${this.$data.apiUrl}/users/loggedOut`, { loggedOut: this.userName });// Send logout event to logger

      axios.post(`${this.$data.apiUrl}/tokens/revoke`); // Revoke org server Access Token via API call

      await this.$auth.revokeAccessToken(); // Revoke default server session's Access Token
      this.$auth.tokenManager.clear(); // Clear all tokens from tokenManager : ensure no token can be reused
      
      this.$auth.signOut(); // Sign out of Okta session      

      localStorage.clear(); // Clear storage so no token or session info remains
      sessionStorage.clear();    
    },
  },
};
</script>
