<template>
  <div class="login">
    <div id="okta-signin-container"></div>
  </div>
</template>

<script>
import OktaSignIn from "@okta/okta-signin-widget";
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import { OktaAuth } from '@okta/okta-auth-js'
import sampleConfig from "../config";
import axios from "axios";

export default {
  name: "Login-okta",
  data() {
    return {
      oktaDomain: process.env.VUE_APP_OKTA_DOMAIN,
      serverId: process.env.VUE_APP_SERVER_ID,
      clientId: process.env.VUE_APP_CLIENT_ID,
      redirectUri: process.env.VUE_APP_REDIRECT_URI,
      apiUrl: process.env.VUE_APP_API_URL,
    }
  },
  async mounted() {
    var issuerParam = new URL(window.location).searchParams.get("iss");

    console.log(issuerParam);
    
    
    this.$nextTick(function () {
      var { issuer, clientId, redirectUri, scopes, useInteractionCodeFlow } = sampleConfig.oidc;

      if (issuerParam != null) {
        issuer = issuerParam;
      }

      console.log(issuer)

      this.widget = new OktaSignIn({ // Widget: auth client, used to get access and id tokens from the Org Authorization Server
        clientId,
        redirectUri,
        logo: require("@/assets/audioptic_logo.jpeg"),
        authParams: {
          issuer,
          scopes,
        },
        useInteractionCodeFlow,
      });
      

      const originalUri = this.$auth.getOriginalUri();
      if (!originalUri) {
        this.$auth.setOriginalUri("/");
      }

      // Search for URL Parameters to see if a user is being routed to the application to recover password
      var searchParams = new URL(window.location).searchParams;
      this.widget.otp = searchParams.get("otp");
      this.widget.state = searchParams.get("state");

      this.widget
        .showSignInToGetTokens({
          el: "#okta-signin-container",
          scopes,
        })
        .then((tokens) => {
          this.$auth.handleLoginRedirect(tokens);

          var logged = this.$auth.token.decode(tokens.idToken.idToken); // Decoding Id Token...
          var isLogged = JSON.stringify(logged.payload);
          var loggedUser = JSON.parse(isLogged).name; //...to get the name of the logged user

          axios.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken.accessToken}`;
          axios.get(`${this.$data.apiUrl}/users/test`); // test backend API is responding      

          localStorage.setItem('accessToken', tokens.accessToken.accessToken); // Store org server Acess Token, which is used for every API calls
          localStorage.setItem('idToken', tokens.idToken.idToken);

          var authClient = new OktaAuth({
            issuer: issuerParam != null ? `${issuerParam}/oauth2/${this.$data.serverId}` : `https://${this.$data.oktaDomain}.com/oauth2/${this.$data.serverId}`, // New authClient instance pointing towards default server
            scopes: ["openid", "profile", "email", "offline_access"],
            clientId: this.$data.clientId,
            redirectUri: redirectUri,
          });

          console.log(authClient)

          var config = {
            baseUrl: issuerParam != null ? issuerParam : issuer,
            authClient: authClient
          };

          var signIn = new OktaSignIn(config); // Session use SSO to get id token from default Authorization Server

          signIn.authClient.token.getWithoutPrompt()
          .then(function(res) {
            var tokens = res.tokens;
            localStorage.setItem('defaultServerIdToken', JSON.stringify(tokens.idToken.claims))
          })
          .catch(error => {
            console.log('Erreur pendant la silent authentication, ' + error);
          });

          axios.post(`${this.$data.apiUrl}/users/logged`, { logged: loggedUser }); // Send login event in the logs             
      })
      .catch(error => console.log(error));   
    });     
  },
  async unmounted() {
    // Remove the widget from the DOM on path change
    this.widget.remove();
  },  
};
</script>
