<template>
  <router-view />
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

::placeholder {
    color: black !important;
  }

body {
    background: #f9f9f9; 
    /*background: #ffffff;*/
}
</style>
